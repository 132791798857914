import { companyConfig } from '@/config/config.js';
import tool from '@/common/tool';

export function getCompanyConfig() {
  const platId = tool.getLocalStorage('PlatId');
  if (companyConfig.has(platId)) {
    return companyConfig.get(platId);
  } else {
    return companyConfig.get('default');
  }
}

export function addLinkTag(href, rel, type) {
  // 创建一个link元素
  var link = document.createElement('link');
  link.href = href;
  link.rel = rel || '';
  link.type = type || 'text/css';

  // 将link元素添加到head中
  document.getElementsByTagName('head')[0].appendChild(link);
}
